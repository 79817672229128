<template>
  <footer class="footer">
    <div class="main_container">
      <div class="footer_top">
        <div class="footer_logo">
          <a href="/">
            <img src="@/assets/images/footer_logo.svg" alt="">
          </a>
          <div class="footer_social">
            <ul>
              <h6>
                Контакты
              </h6>
              <li>
                <a href="tel:+80000000000">
                  8 000 000 00 00
                </a>
              </li>
              <li>
                <a href="mailto:email@address.com">
                  email@address.com
                </a>
              </li>
            </ul>
            <div class="social_media">
              <a href="#">
                <img src="@/assets/images/telegram_icon.svg" alt="">
              </a>
              <a href="#">
                <img src="@/assets/images/vk_icon.svg" alt="">
              </a>
              <a href="#">
                <img src="@/assets/images/youtube_icon.svg" alt="">
              </a>
              <a href="#">
                <img src="@/assets/images/instagram_icon.svg" alt="">
              </a>
            </div>
          </div>
        </div>
        <div class="footer_blocks">
          <ul class="footer_link">
            <h6>
              Интеграции
            </h6>
            <li>
              <a href="#">
                Маркетплейсы
              </a>
            </li>
            <li>
              <a href="#">
                Мой Склад
              </a>
            </li>
            <li>
              <a href="#">
                1 С
              </a>
            </li>
            <li>
              <a href="#">
                Сторонние ресурсы
              </a>
            </li>
          </ul>
          <ul class="footer_link">
            <h6>
              Ресурсы
            </h6>
            <li>
              <a href="#">
                Блог
              </a>
            </li>
            <li>
              <a href="#">
                Youtube
              </a>
            </li>
            <li>
              <a href="#">
                Статьи
              </a>
            </li>
            <li>
              <a href="#">
                Поддержка
              </a>
            </li>
          </ul>
          <ul class="footer_link">
            <h6>
              Общая информация
            </h6>
            <li>
              <a href="#">
                Пользовательское соглашение
              </a>
            </li>
            <li>
              <a href="#">
                О защите персональных данных
              </a>
            </li>
            <li>
              <a href="#">
                Полезная информация
              </a>
            </li>
            <li>
              <a href="#">
                Авторское право
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="footer_bottom">
        <span>
          UniSeller © 2024   Все права защищены
        </span>
      </div>
    </div>
  </footer>
</template>
